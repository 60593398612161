
import Box from '../components/Box'

const MathLearning = () => {

    return (
        <>
            <Box />
        </>
    )
}


export default MathLearning