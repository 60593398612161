// Gradients taken from: https://webgradients.com/
export default [
	// {
	// 	title: "a running brook",
	// 	selected: false,
	// 	font: null,
	// 	color: null,
	// 	backgroundColor: null,
	// 	text: "with no falter— nor caution, \\nher waters run gently against stone and foundation.\\ndeep enough for life to swirl and bubble without ration;\\nsoft enough to float on seeds— those born of nature’s passion.\\n \\nthose who snarl and those who gather mend among her shores.\\ninstinctually, intake and swell; her flavour: it restores.\\nthe winds, they sway in parallel: an ecclesiastes breeze.\\nthey whisper:\\neven in autumn, her beauty never leaves.\\n",
    //     description: '#a8edea → #fed6e3',
    //     css: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)',
    //     height: 300,
	// 	brief: true
	// },
	{
		title: "Belong",
		selected: false,
		font: null,
		color: null,
		backgroundColor: null,
		text: "I was taught to look both ways, but grow into one. Yet I find, there’s more than one story. \\n\\n there’s more than one narrative. \\n \\n there’s more than one protagonist.\\n\\n There’s more than one: villain, opinion, decision, and step; \\n\\n more than one: challenge and limit, tangent and reason.\\n \\nmore than one: season, breath, horizon and shape \\n\\n more than one: storm, valley, breeze and escape\\n\\nthere’s more than one option, but I must make a choice. it won’t be a chorus, without my added voice. \\n\\nI’ll find community in ‘others’. defend a place that I choose. protect and admire the one we can’t lose.\\n\\n there’s multiple rhythms, hearts beating as one. \\n\\nthere’s more than one creature that’s singing our song. \\n\\n now it is crucial. allow this thought to dawn there’s only one earth to which all belong.",
		description:  '#a8edea → #fed6e3',
		css: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)',
        height: 400,
		brief: false
	},
	{
		title: "While We’re Here",
		selected: false,
		font: null,
		color: null,
		backgroundColor: null,
		text: "when I’m right, I’m right beside you.\\nwhen I’m wrong, we pursue answers.\\nwhen I’m tall, you raise me higher.\\nwhen I’m small, we sneak by.\\nwhen I’m strong, it’s due to numbers\\nwhen I’m weak, still you overcome.\\nwhen I’m lonely, I’m with another.\\nwhen I’m solitary, your spirit dwells.\\nwhen I’m blessed, we share in riches.\\nwhen I’m sober, you’re clear as day.\\nwhen I’m intoxicated, your fervor swells.\\nwhen I’m nourished, your taste lingers.\\nwhen I’m quenched, you’re overflowing.\\nwhen I’m starved, you must be distant.\\ndearest be near; while we’re still here.",
        description: '#f5f7fa → #c3cfe2',
        css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        height: 400,
		brief: true
	},
	{
		title: "present perfect",
		selected: false,
		font: null,
		color: "whitesmoke",
		backgroundColor: null,
		text: "when we speak it’s a chorus. \\nwhen we stop it’s suspense. \\n \\nharbor no sorrow from before us. \\ndetect present tense. \\n \\nleave uncertainty in the kind words of a past liar. \\nlet future doubt begin to flicker out from our minds before it mounts a breath. \\nfor without air, doubt has nothing. It cannot shout through doorways-- \\nnor echo cross mountain tops whose snow would crumble in disdain. \\n \\npropose a rest to society's noise--  \\nwhose press spout only dissonance from their ever growing message. \\n\\nfor ‘tense’ is unfitting, \\nat least in our presence. \\n\\nhere we embrace the joys of imperfection. \\nhere we are present for the bouncing harmony that is our touch. \\nhere we perfect in resonance of kindred spirit. \\nhere we are present; our boundless tone persists. \\nhow it rings! \\n",
        description: '#e0c3fc → #8ec5fc',
        css: 'linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)',
        height: 400,
		brief: false
	},
	{
		title: "heights",
		selected: false,
		font: null,
		color: "teal",
		backgroundColor: null,
		text: "even when life feels suffocating--\\nwhen the constant reminders float nearby--\\nwhen old words echo--\\nwhen your chest is heavy--\\nwhen your shoulders and head sink, and all you seem to see is dirty ground\\n\\nthere is hope.\\nthere is a place for us.\\nfree from pain and miseries of the mind.\\nfor now it is an escape: each other’s arms\\n\\nbut what we plant we will surely reap.\\nfertilized in the old ways we bury.\\nwe can grow to unimaginable heights.\\ntwo stalks, weaving against each other again and again as we soar through the sky.\\n\\nand as we bloom in repetition, I truly believe that the winds will be favorable--\\nthat we will be at peace.",
        description: '#f093fb → #f5576c',
        css: 'linear-gradient(135deg, #f093fb 0%, #f5576c 100%)',
        height: 400,
		brief: false
	},
	{
		title: "Joy",
		selected: false,
		font: null,
		color: "lightcoral",
		backgroundColor: null,
		text: "Every week we hit the next high note:\\nCutesy-- like falsetto, \\nBut true in every sense.\\nA melody of endless growth,\\nFor my perfect complement.\\n\\nMy cheeks ache from smiling:\\nI churn each time I see you laugh.\\nI'm gripped by warmth in everything we do--\\nWhether dripped in ecstasy or working by your side,\\nWe find joy in aimless moments and joy when we aspire.\\n\\nI know you feel it too,\\nBut still I let it echo,\\nFeel it; bold and emphasized.\\nTruly Brooke-- I love you.",
        description: '#fdfbfb → #ebedee',
        css: 'linear-gradient(135deg, #E3FDF5 0%, #FFE6FA 100%)',
        height: 400,
		brief: true
	},
	{
		title: "time",
		selected: false,
		font: "http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf",
		color: "aquamarine",
		backgroundColor: null,
		text: "time shared is the greatest flattery. \\nstick near the one who savors each drop.\\nwho cultures the honey of seconds and minutes, \\nwho is overjoyed by a simple smile-- \\nwhose self congeals around you-- \\nwhilst yours ebs towards them. \\n\\nfor you can shiver with company--\\nand words can echo through flesh without landing. \\nso find the one that makes the moment sing.\\nwho hears you in crescendo or silence.\\nwho holds the hands of time.\\nwho shares in your divine.",
        description: '#5ee7df → #b490ca',
        css: 'linear-gradient(135deg, #5ee7df 0%, #b490ca 100%)',
        height: 400,
		brief: true
	},
	{
		title: "perennials",
		selected: false,
		font: "http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf",
		color: "pink",
		backgroundColor: "green",
		text: "moments from nothing \\njoy without cue \\nseeds of minutes and seconds \\ngrow from time with you \\n\\nripe to flower, on even gloomy days \\nstored hours of warmth, savvy, and play \\n\\nthough pedals may drop, from time to time \\nour roots weave together, and upwards they’ll climb \\nthey’ll vine up above our chemical soils \\nand shine in the dew of earth’s many souls \\n\\nyou who’d embrace me \\nand the thorns that I wear \\nI’m ready to share in our next season’s air \\n\\nyou are my liquid sun \\nmy potted soil \\nmy towering trellis \\nthe love of my life",
        description: '#d299c2 → #fef9d7',
        css: 'linear-gradient(135deg, #d299c2 0%, #fef9d7 100%)',
        height: 200,
		brief: false
	},
	{
		title: "Grow Until Then",
		selected: true,
		font: null,
		color: "pink",
		backgroundColor: null,
		text: "As we march for the day\\nwhen our trust has no end\\nuntil then—\\nI’ll connect the dots on your shoulders,\\n\\nI’ll kiss the mark on your nape,\\nI’ll pull you close to comfort,\\nI’ll listen to your days.\\n\\nI’ll bend you over counters, and\\nslap your ass that begs.\\n\\nI’ll cherish every taste and scent\\nI’m awe-struck in your gaze.\\n\\nFrom your mind\\nI’m predisposed\\nTo give into the chase. \\n\\nYour vision is\\nso clear to me\\nthough fogged post each embrace.\\n\\nKnow each time\\nyou strike a pose\\nor hit me with\\nyour half-squint smile;\\n\\nI’m lost in joy \\n—right satisfied\\nmy every sense is left beguiled.\\n\\nThe bottom may hold crowded threads\\n—that dash and loop in disarray.\\nBut we are ever intertwined.\\nAn enveloped display,\\nOur marveled souls: a growing scene that\\nthe needle joins each day.",
        description: '#ebc0fd → #d9ded8',
        css: 'linear-gradient(135deg, #ebc0fd 0%, #d9ded8 100%)',
        height: 400,
        shrink: false,
		brief: false
	}
]